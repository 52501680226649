<div id="divMainDashboard">
	<div id="divSignInContainer">
		<section class="SectionFormSignInContainer">
			<ng-container>
				<!-- <app-header></app-header> -->
			</ng-container>
			<form>
				<div class="DivFormHeader" style="text-align: center;">
					<h1>Profil</h1>
					<br>
					<label>Lihat profil Anda disini</label>
				</div>
	
				<div class="DivForm" style="padding-top: 1.9vw; padding-left: 8vw; padding-right: 8vw; padding-bottom: 1.3vw;">
					<h3>Akun</h3>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column" style="margin-top: 0px;">
							<fieldset>
								<section class="SectionFormInput">
									<div>
										<label for="textFirstName" class="LabelPlaceholderGeneral">Sebagai</label>
										<p class="ParagraphSmall">{{_modelUserPublic.getAgentTypeName()}}</p>
									</div>
								</section>
							</fieldset>
						</div>
					</div>
	
					<h3>Data</h3>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column" style="margin-top: 0px;">
							<fieldset>
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="textFirstName" class="LabelPlaceholderGeneral">Full Name</label>
										<p class="ParagraphSmall">{{_modelUserPublic.FullName}}</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="textBirthPlace" class="LabelPlaceholderGeneral">Tempat Lahir</label>
										<p class="ParagraphSmall">{{_modelUserPublic.BirthPlace}}</p>
									</div>
								</section>
	
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="dateModelBirthDate" class="LabelPlaceholderGeneral">Tanggal
											Lahir</label>
										<p class="ParagraphSmall">{{_modelUserPublic.BirthDate | initiateWithoutUTC | convertToDateShort}}</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="textEmail" class="LabelPlaceholderGeneral">Email</label>
										<p class="ParagraphSmall">{{_modelUserPublic.Email | convertEmptyToDash}}</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="textPhonumber" class="LabelPlaceholderGeneral">Nomor Ponsel</label>
										<p class="ParagraphSmall">{{_modelUserPublic.MobilePhone | convertEmptyToDash}}</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="textID" class="LabelPlaceholderGeneral">Identification Number
											(KTP)</label>
										<p class="ParagraphSmall">{{_modelUserPublic.IdentificationNumber |
											convertEmptyToDash}}</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="textNPWP" class="LabelPlaceholderGeneral">NPWP</label>
										<p class="ParagraphSmall">{{_modelUserPublic.TaxNumber | convertEmptyToDash}}</p>
									</div>
								</section>
	
								<section *ngIf="_modelUserPublic.modelAgent?.AgentType === 1" class="SectionFormInput">
									<div class="margin20">
										<label for="textCompanyName" class="LabelPlaceholderGeneral">Nama Perusahaan</label>
										<p class="ParagraphSmall">{{_modelUserPublic.modelAgent?.CompanyName |
											convertEmptyToDash}}</p>
									</div>
								</section>
	
							</fieldset>
						</div>
	
						<div class="DivForm2Column" style="margin-top: 0px;">
							<fieldset>
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="selectMaritalStatus" class="LabelPlaceholderGeneral">Status
											Perkawinan</label>
										<p class="ParagraphSmall">{{_modelUserPublic.getMaritalTypeName() |
											convertEmptyToDash}}</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="selectReligion" class="LabelPlaceholderGeneral">Agama</label>
										<p class="ParagraphSmall">{{_modelUserPublic.getReligionTypeName() |
											convertEmptyToDash}}</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="selectGender" class="LabelPlaceholderGeneral">Jenis Kelamin</label>
										<p class="ParagraphSmall">{{_modelUserPublic.getGenderTypeName() |
											convertEmptyToDash}}</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="textAddress" class="LabelPlaceholderGeneral">Alamat</label>
										<p class="ParagraphSmall">{{_modelUserPublic.Address | convertEmptyToDash}}</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="textAccountNumber" class="LabelPlaceholderGeneral">Nomor Rekening
											BRI</label>
										<p class="ParagraphSmall">{{_modelUserPublic.AccountNumber | convertEmptyToDash}}
										</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="selectBranch" class="LabelPlaceholderGeneral">Cabang</label>
										<p class="ParagraphSmall">{{_modelUserPublic.modelBranch?.Name| convertEmptyToDash}}
										</p>
									</div>
								</section>
	
								<section class="SectionFormInput">
									<div class="margin20">
										<label for="textMothersMaiden" class="LabelPlaceholderGeneral">Nama Ibu
											Kandung</label>
										<p class="ParagraphSmall">{{_modelUserPublic.MothersMaidenName |
											convertEmptyToDash}}</p>
									</div>
								</section>
							</fieldset>
						</div>
					</div>
	
	
	
					<div>
						<h3>Sertifikat</h3>
						<div *ngFor="let listCertificate of  _modelUserDocument.listModelAgentDocumentModel; let numberIndex = index">
							<div class="DivForm"
								*ngIf="listCertificate.DocumentType === 4 && listCertificate.Token !== null">
								<div *ngIf="listCertificate.Token" class="DivFormHorizontalContainer">
									<div class="DivForm2Column">
										<fieldset >
											<section class="SectionFormInput">
												<div class="DivFormInput DivFormInputWithAttachment">
													<figure *ngIf="listCertificate.Name" class="FigureInputAttachment">
														<img src="{{listCertificate.Extension | convertExtensionToIcon}}"
															alt="{{listCertificate.Name}}">
													</figure>
													<label for="fileCertificateDocs"
														class="LabelPlaceholderSignIn">{{listCertificate.getSupportingDocumentName()
														| convertEmptyToDash}}</label>
													<label (click)="callDownloadSupportingDocumentForAgent(this, numberIndex)" 
														class="LabelViewFile LabelSizeFile" for="fileCertificateDocs">
														<span>{{listCertificate.Name | convertEmptyToDash}}</span>
													</label>
												</div>
											</section>
										</fieldset>
									</div>
	
									<div class="DivForm2Column">
										<fieldset>
											<section class="SectionFormInput">
												<div class="DivFormInput DivFormInputWithAttachment">
													<label for="dateModelPeriodDate"
														class="LabelPlaceholderSignIn LabelPlaceholderLeft">Tanggal
														Kadaluarsa Sertifikat</label>
													<label class="LabelNoViewFile LabelSizeFileRight" 
														for="dateModelPeriodDate">
														<span>{{_modelUserPublic.modelAgent?.CertificateExpiration |
															convertToDateShort}}</span>
													</label>
												</div>
											</section>
										</fieldset>
									</div>
								</div>
							</div>
						</div>
	
						<h3>Dokumen Pendukung</h3>
						<div class="DivForm">
							<div class="DivFormHorizontalContainer">
								<ng-container *ngFor="let listSupportingDocument of _modelUserDocument.listModelAgentDocumentModel; let numberIndex = index">
									<ng-container *ngIf="listSupportingDocument.DocumentType !== 4 && listSupportingDocument.DocumentType !== 9 && listSupportingDocument.DocumentType !== 10 && listSupportingDocument.Token !== null">
										<div class="DivForm2Column">
											<fieldset>
												<section class="SectionFormInput">
													<div class="DivFormInput DivFormInputWithAttachment">
														<figure *ngIf="listSupportingDocument.Name"
															class="FigureInputAttachment">
															<img src="{{listSupportingDocument.Extension | convertExtensionToIcon}}"
																alt="{{listSupportingDocument.Name}}">
														</figure>
														<label
															[for]="'file' + listSupportingDocument.getSupportingDocumentName() + 'Docs'"
															class="LabelPlaceholderSignIn">{{listSupportingDocument.getSupportingDocumentName()}}</label>
														<label (click)="callDownloadSupportingDocumentForAgent(this, numberIndex)" *ngIf="listSupportingDocument.Token"
															class="LabelViewFile LabelSizeFile"
															[for]="'file' + listSupportingDocument.getSupportingDocumentName() + 'Docs'">
															<span>{{listSupportingDocument.Name}}</span>
														</label>
													</div>
												</section>
											</fieldset>
										</div>
									</ng-container>
								</ng-container>
	
							</div>
						</div>
					</div>
	
					<h3>E-PKS</h3>
					<div class="DivForm">
						<div class="DivFormHorizontalContainer">
							<ng-container *ngFor="let listSupportingDocument of _modelUserDocument.listModelAgentDocumentModel; let numberIndex = index">
								<ng-container *ngIf="listSupportingDocument.DocumentType === 9 || listSupportingDocument.DocumentType === 10" >
									<div class="DivForm2Column">
										<fieldset>
											<section class="SectionFormInput">
												<div class="DivFormInput DivFormInputWithAttachment">
													<figure *ngIf="listSupportingDocument.Token"
														class="FigureInputAttachment">
														<img src="{{listSupportingDocument.Extension | convertExtensionToIcon}}"
															alt="{{listSupportingDocument.Name}}">
													</figure>
													<label
														[for]="'file' + listSupportingDocument.getSupportingDocumentName() + 'Docs'"
														class="LabelPlaceholderSignIn">{{listSupportingDocument.getSupportingDocumentName()}}</label>
													<label (click)="callDownloadSupportingDocumentForAgent(this, numberIndex)" *ngIf="listSupportingDocument.Token"
														class="LabelViewFile LabelSizeFile"
														[for]="'file' + listSupportingDocument.getSupportingDocumentName() + 'Docs'">
														<span>{{listSupportingDocument.Name}}</span>
													</label>
												</div>
											</section>
										</fieldset>
									</div>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="DivButtonContainerSmallCenter">
					<input (click)="goToEditProfile()" type="button" value="UBAH">
				</div>
			</form>
			<div class="DivAlignCenterContainer">
			</div>
		</section>
	</div>
</div>
