<div id="divMainDashboard">
    <div class="DivPageContainer" id="divMainLandingContainer">
        <section class="SectionMain" [ngStyle]="getImageMain()">
            <img class="ImgShapeMain1" src="../../../assets/photos/sakina/landing_ams.png">
            <!-- <img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif"> -->
            <div class="DivFormHorizontalContainer" id="divMainLanding">
                <div class="DivForm2Column DivHeaderCenterContainer">
                    <div class="DivHeaderContentContainer">
                        <h1 class="FontSoftColor">Agent Management System</h1>
                        <h5 class="FontSoftColor">Hi, Thank you for registering as a Prospective BRI Insurance Agent. Next you will go through several registration stages</h5>
                        <!-- <p class="FontSoftColor Width100">{{_modelHeaderContent.DescriptionIDN}}</p> -->
                        <!-- <button class="ButtonSubmit" *ngIf="_modelUserSignIn === null || _modelUserSignIn === undefined" (click)="goToSignIn();"><label class="FontSoftColor">Login</label></button> -->
                    </div>
                    <!-- <div class="DivContentSearchContainer">
                        <div class="DivContentSearch">
                            <img src="../../assets/icons/sakina/icon_search.svg"  alt="Search">
                            <div class="DivContentSearchDetailFilter">
                                <input type="text" placeholder="Cari di disini">
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="DivForm2Column DivSocialMediaCenterContainer">
                    <div>
                        
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- START MODAL -->

<!-- END MODAL -->