<div id="divMainDashboard">
	<div id="divNotificationContainer">
		<section>
			<h3>Notification</h3>
		</section>
		<section class="SectionBubbleCard" *ngIf="_modelNotification.EPKSStatusMessage !== null">
			<div class="DivImageBubleCard">
				<img src="../../../assets/icons/sakina/icon_rounded_notification.svg" alt="BRI Insurance - Icon Rounded">
			</div>
			<div class="DivContentBubleCard">
				<h5>E-PKS</h5>
				<p>{{ _modelNotification.EPKSStatusMessage}}</p>
				<span>{{ _modelNotification.EPKSDate | initiateWithoutUTC | convertToTime }}</span>
			</div>
		</section>
		<section *ngIf="_modelNotification.EPKSStatusMessage === null">
			<label>Belum Ada Notifikasi</label>
		</section>
		<section class="SectionBubbleCard" *ngIf="_modelNotification.CertificateStatusMessage !== null">
			<div class="DivImageBubleCard">
				<img src="../../../assets/icons/sakina/icon_rounded_notification.svg" alt="BRI Insurance - Icon Rounded">
			</div>
			<div class="DivContentBubleCard">
				<h5>Sertifikasi</h5>
				<p>{{ _modelNotification.CertificateStatusMessage }}</p>
				<span>{{ _modelNotification.CertificateDate | initiateWithoutUTC | convertToTime }}</span>
			</div>
		</section>
	
	</div>
</div>
