//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_CLAIM_API: string = environment.URL_CLAIM_API;
	static STRING_URL_BRINS_WEB: string = environment.URL_BRINS_WEB;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForAgent
	`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;
	static STRING_URL_BRANCH_SELECTBRANCHBYLOCATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByLocation`;
	static STRING_URL_BRANCH_SELECTBRANCHFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForCustomer`;
	static STRING_URL_BRANCH_SELECTBRANCHFORAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForAgent`;
	static STRING_URL_BRANCH_SELECTPROVINCE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectProvince`;
	static STRING_URL_BRANCH_SELECTBRANCHBYPROVINCE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByProvince`;
	static STRING_URL_BRANCH_SELECTBRANCHBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByAttributes`;

	//#endregion


	//#region CUSTOMER CONTROLLER

	static STRING_PATH_CONTROLLER_CUSTOMER: string = "/Customer";

	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByProfileID`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByPolicyNumber`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYLICENSEPLATE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByLicensePlate`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByAttributes`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERTICKETBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerTicketByEmail`;
	static STRING_URL_CUSTOMER_VERIFYCUSTOMERTICKETBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/verifyCustomerTicketByEmail`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByEmail`;
	static STRING_URL_CUSTOMER_REGISTERCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/registerCustomerByEmail`;
	static STRING_URL_CUSTOMER_VERIFYCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/verifyCustomerByEmail`;
	static STRING_URL_CUSTOMER_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/signOut`;

	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;

	//#endregion


	//#region LANDING CONTENT CONTROLLER

	static STRING_PATH_CONTROLLER_LANDINGCONTENT: string = "/LandingContent";

	static STRING_URL_LANDINGCONTENT_SELECTLANDINGCONTENTBYATTRIBUTEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LANDINGCONTENT}/selectLandingContentByAttributesForCustomer`;

	//#endregion

	//#region USER

	static STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM: string = "/AgencyManagementSystem";

	static STRING_URL_AGENCYMANAGEMENT_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/signOut`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_SELECTAGENTBYEMAILFORSIGNIN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/selectAgentByEmailForSignin`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_SELECTAGENTBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/selectAgentByEmail`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_SELECTAGENTBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/selectAgentByUserID`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_VERIFYAGENTBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/verifyAgentByEmail`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_INSERTUSERFORAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/insertUserForAgent`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_INSERTSUPPORTINGDOCUMENTFORAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/uploadSupportingDocumentForAgent`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_UPDATESUPPORTINGDOCUMENTFORAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/updateSupportingDocumentForAgent`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_UPDATEUSERFORAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/updateUserForAgent`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_SELECTNOTIFICATIONAGENTBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/selectNotificationAgentByUserID`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_DOWNLOADSUPPORTINGDOCUMENTFORAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/downloadSupportingDocumentForAgent`;
	static STRING_URL_AGENCYMANAGEMENTSYSTEM_SELECTISNOTIFICATIONEXISTBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AGENCYMANAGEMENTSYSTEM}/selectIsNotificationExistByUserID`;

	//#endregion


	//#region PRODUCT ASRI

	static STRING_PATH_CONTROLLER_PRODUCTASRI: string = "/ProductAsri";

	static STRING_URL_PRODUCTASRI_CALCULATEPREMIUMFORAGENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/calculatePremiumForAgent`;

	//#endregion


	//#region PRODUCT DIRI

	static STRING_PATH_CONTROLLER_PRODUCTDIRI: string = "/ProductPersonalAccident";

	static STRING_URL_PRODUCTDIRI_CALCULATEPREMIUMFORAGENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDIRI}/calculatePremiumForAgent`;

	//#endregion


	//#region PRODUCT DIRI

	static STRING_PATH_CONTROLLER_PRODUCTOTO: string = "/ProductOto";

	static STRING_URL_PRODUCTOTO_CALCULATEPREMIUMFORAGENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/calculatePremiumForAgent`;

	//#endregion


	//#region VEHICLE

	static STRING_PATH_CONTROLLER_VEHICLEBRAND: string = "/VehicleBrand";
	static STRING_PATH_CONTROLLER_VEHICLEMODEL: string = "/VehicleModel";
	static STRING_PATH_CONTROLLER_VEHICLELOCATION: string = "/VehicleLocation";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEBRANDFORAGENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEBRAND}/selectVehicleBrandForAgent`;
	static STRING_URL_VEHICLEMODEL_SELECTVEHICLEMODELBYVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMODEL}/selectVehicleModelByVehicleBrandForAgent`;
	static STRING_URL_VEHICLELOCATION_SELECTVEHICLELOCATIONFORAGENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocationForAgent`;
	//#endregion


	//#region LICENSE PLATE

	static STRING_PATH_CONTROLLER_LICENSEPLATE: string = "/LicensePlatePrefix";

	static STRING_URL_LICENSEPLATE_SELECTLICENSEPLATEPREFIX: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LICENSEPLATE}/selectLicensePlatePrefix`;

	//#endregion

}

//#endregion
